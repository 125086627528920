.app-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  font-size: 13px;
  color: #ccc;
  padding: 8px 0;
}

.app-main {
  position: relative;
  min-height: 100vh;
}
