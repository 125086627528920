.loader-wrapper {
  position: relative;
}
.loader-wrapper .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  content: "";
}
.loader-wrapper .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 11px;
  z-index: 2;
  width: 56px;
  text-align: center;
  height: 48px;
  margin: -19px -24px;
}

.anim-spinner {
  animation: spinner 0.6s ease-in-out infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

