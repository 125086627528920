$primary: #0098ff;
$bg_col_hover: #ebebeb;
$border-radius: 0.15em;

$color_up: rgba(green, 0.22);
$color_down: rgba(red, 0.22);

$input-btn-padding-y: 0.25rem !default;
$input-btn-padding-x: 0.5rem !default;

$white: #fff;
$black: #000;
